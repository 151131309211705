<template>
    <div class="main-bg">
        <div v-show="loading" id="semiTransparenDiv"></div>

        <div class="main-bg-wrapper">
            <div class="logo">
                <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
                <img :src="require('@/assets/images/logo-name.png')">
            </div>
            <div class="form-table">
                <div class="form-inner">
                    <p>Log In To Your Account</p>
                </div>
                <div class="form">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Username</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Username" v-model="username">
                        <span v-if="username_error" class="error_alert">{{username_error_message}}</span>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input :type="passwordFieldType" class="form-control" id="exampleInputPassword1" placeholder="Enter Password" v-model="password">
                        <i :class="['fa ', passwordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true" @click="switchPasswordVisibility()"></i>
                        <span v-if="pwd_error" class="error_alert">{{pwd_error_message}}</span>

                    </div>
                     <div class="forgot-password">
                         <router-link to="/request-reset-password" replace>FORGOT PASSWORD?</router-link>
                    </div>
                </div>
                <div class="button">
                    <a href="javascript:void(0)" @click="login()"><button>LOG IN</button></a>
                </div>

            </div>
        </div>
  </div>

</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'
    export default {
        name: 'Login',
        data () {
            return {
                username:'',
                password: '',
                loading: false,
                errors: [],
                passwordFieldType:"password",
                usernameFieldType:"text",
                username_error:false,
                pwd_error:false,
                username_error_message:"",
                pwd_error_message:"",


            }
            },
        methods: {

            async login(){
                this.errors=[]
                if (this.validate()) {
                    this.loading= true;
                    try{
                        const data =  { "username": this.username,"password": this.password, };
                        const headers = { 
                            "Content-Type": "application/json",
                        };
                        console.log('--------------',baseUrl)
                        const response =await  axios.post(`${baseUrl}/auth/token`, data,{headers})
                        // console.log(response.data);
                        this.loading = false;
                        await this.$toasted.success("login Successful");
                        await this.setUser(response.data)
                        if (response.data.user_data.password_set == false){
                            await this.$router.push('/change-password')
                        }else{
                            await this.$router.push('/brands-listing')
                        }
                    }
                    catch (e) {
                        this.loading = false;
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                    }

                }

            },

            validate(){
                console.log('validating')
                this.pwd_error=false
                this.username_error=false
                if ((!this.username) || (this.username.trim().length<=0)) {
                    this.username_error=true
                    this.username_error_message="Please provide username!"
                    this.errors.push("Please provide username!")
                    this.$toasted.error('Please provide username!');
                }

                if ((!this.password) || (this.password.trim().length<=0) ) {
                    this.pwd_error=true
                    this.pwd_error_message="Please provide password!"
                    this.errors.push("Please provide password!")
                    this.$toasted.error('Please provide password!');
                }

                if ((this.password) && (this.password.trim().length<8)  ){
                    this.pwd_error=true
                    this.pwd_error_message="Your password must contain at least 8 characters."
                    this.errors.push("Your password must contain at least 8 characters.")
                    this.$toasted.error('Your password must contain at least 8 characters.');
                }
                if (Number(this.password)) {
                    this.pwd_error=true
                    this.pwd_error_message="Your password can't be entirely numeric."
                    this.errors.push("Your password can't be entirely numeric.")
                    this.$toasted.error('Your password cannot be entirely numeric.');
                }



                if (!this.errors.length) {
                    return true;
                }
                 
                 
            },

            switchPasswordVisibility(){
                this.passwordFieldType = this.passwordFieldType === "password" ? "text":"password";

            },

            async setUser(data){
                try{
                    await this.$store.commit("setUserData",data.user_data);
                    await this.$store.commit("setAccessToken", data.access);
                    await this.$store.commit("setRefreshToken", data.refresh);

                } 
                catch (e) {
                    console.log('error is',e);
                    this.$toasted.error(e);
                }

            },
  
        
        },
        mounted(){

        },
        created(){

        },

    }

</script>


<style>

</style>